import { ofType, StateObservable } from 'redux-observable'
import { mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { BroadcastAC } from '../../../../actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../../actions/cancel-now/actions'

type Action = DN.Services.Bcast.Room.Events.Connection.Disonnected<{}>

const actionType: Action['type'] = '@dn/Bcast-conn-disconnected'

export const bcastV2EventsConnDisconnectedEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(actionType),

    mergeMap(() => {
      return of(
        BroadcastAC.exit('session-end'),

        CancelNowAC.cancelAll([CancelReasons.SessionEnded]),
      )
    }),
  )
