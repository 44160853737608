import { useState, useRef, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromEvent, take, filter, pairwise, merge, mergeMap, takeUntil } from 'rxjs'
import { ConstEventsElement, ConstMouseButtons, ConstEventsVideo } from '@dn/constants'
import { UtilsPaintFramework } from '@dn/utils'
import { HooksData } from '@dn/hooks'

import { Zoom } from '../../../../../constants/zoom'
import { StoreState } from '../../../../../models/app/model'
import { PaintBcastStreamVideoMR } from '../../../../../models/bcast/paint-bcast-stream/model'
import { UtilsLog } from '../../../../../utils/logs'
import { Config } from '../../../../../config'

// ~~~~~~ Hook

export const useJoinedBehaviourLogicContentMain = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  const [videoIsPlaying, setVideoIsPlaying] = useState(false)

  const [videoHasSrcObj, setVideoHasSrcObj] = useState(false)

  const [showMiniVideo, setShowMiniVideo] = useState(true)

  // - Store

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  const streamManagerSub = useSelector((state: StoreState) => state.streamManagerSubMain)

  const curCamMicStream = useSelector(
    (state: StoreState) => state.streamManagerSubCamMic.subscriber,
  )

  const paintBcastStreamEnabled = useSelector((state: StoreState) => state.paintBcastStream.enabled)

  const showAi = useSelector((state: StoreState) => state.aiConfig.uiStatus === 'running')

  const { zoom, originalVideoH, originalVideoW } = useSelector(
    (state: StoreState) => state.paintBcastStreamVideo,
  )

  const uiPipOpenState = useSelector((state: StoreState) => state.uiPip.openState)

  const rootWebrtcKind = useSelector((state: StoreState) => state.root.version)

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  const videoWrapperRef = useRef<HTMLDivElement>(null)

  const toolsWrapperRef = useRef<HTMLDivElement>(null)

  const videoRef = useRef<HTMLVideoElement>(null)

  const miniVideoRef = useRef<HTMLVideoElement>(null)

  // ~~~~~~ Computed

  const video = videoRef.current

  const screenStreamForVideo =
    streamManagerSub.subscriber?.getMediaStream() || dnBcast.remoteScreenStream

  const zoomWidthAndHeight = `${zoom}%`

  const hasZoom = zoom > Zoom.Min

  const prevPaintBcastStreamEnabled = HooksData.PrevValue.useHook(paintBcastStreamEnabled)

  const shouldShowMiniVideo = showMiniVideo && uiPipOpenState === 'closed'

  const isBcastV1 =
    !Config.Features.BcastService2 ||
    (Config.Features.BcastService2 && Config.Features.BcastCheckVersion && rootWebrtcKind === 'v1')

  // ~~~~~~ Dependent Hooks

  const prevZoom = HooksData.PrevValue.useHook(zoom)

  // ~~~~~~ Handlers

  function onClickCloseMiniVideo() {
    setShowMiniVideo(false)
  }

  // ~~~~~~ Callbacks

  // - Set video wrapper Width/Height based on origial video Width/Height

  const setVideoWrapperSize = useCallback(() => {
    const self = selfRef.current
    const toolsWrapper = toolsWrapperRef.current
    const videoWrapper = videoWrapperRef.current

    if (!originalVideoW || !originalVideoH || !self || !toolsWrapper || !videoWrapper) {
      return
    }

    const relW = self.clientWidth
    const relH = self.clientHeight - toolsWrapper.clientHeight

    const [, height] = UtilsPaintFramework.calcRelativeSize({
      oW: originalVideoW,
      oH: originalVideoH,
      relW,
      relH,
      scale: 1,
    })

    // Width should be always 100%

    videoWrapper.style.height = `${height}px`

    //
  }, [originalVideoW, originalVideoH])

  // - Check if original video dimensions have changed and update if required

  const updateOriginalVideoDimensions = useCallback(() => {
    if (!video) return

    const { videoWidth, videoHeight } = video

    if (videoWidth === originalVideoW && videoHeight === originalVideoH) {
      return
    }

    dispatch(PaintBcastStreamVideoMR.originalVideoW.MC.change(videoWidth))
    dispatch(PaintBcastStreamVideoMR.originalVideoH.MC.change(videoHeight))

    //
  }, [dispatch, originalVideoH, originalVideoW, video])

  // ~~~~~~ Effects

  // - On start video play, set video, its dimension and the playing status as trure

  useEffect(() => {
    if (!video) return

    const sub = fromEvent(video, ConstEventsVideo.Playing)
      .pipe(take(1))
      .subscribe({
        next: () => {
          //

          dispatch(PaintBcastStreamVideoMR.video.MC.change(video))

          updateOriginalVideoDimensions()

          setVideoIsPlaying(true)

          //
        },
      })

    return () => {
      sub.unsubscribe()
    }
  })

  // - Add video source on stream ready

  useEffect(() => {
    if (!video && screenStreamForVideo) {
      setUpdate(performance.now())

      return
    }

    if (!video || !screenStreamForVideo || videoHasSrcObj) return

    video.srcObject = screenStreamForVideo

    // Minivideo

    const miniVideo = miniVideoRef.current

    if (miniVideo) {
      miniVideo.srcObject = screenStreamForVideo
    }

    setVideoHasSrcObj(true)

    // In Firefox (since 91.0), track.getSettings() is undefined, undefined.
    // Because that we have the fromEvent(video, playing)

    const track = screenStreamForVideo.getVideoTracks()[0]

    const { width, height } = track.getSettings()

    dispatch(PaintBcastStreamVideoMR.originalVideoW.MC.change(width || 0))
    dispatch(PaintBcastStreamVideoMR.originalVideoH.MC.change(height || 0))

    //
  }, [dispatch, screenStreamForVideo, updateOriginalVideoDimensions, video, videoHasSrcObj])

  // - On resize video (like changing video source), check original video dimensions
  //   We don't do it meanwhile the user is painting to not damage the canvas dimensions result
  //   So we have to check the dimensions on end painting

  useEffect(() => {
    if (!video) return

    const subResize = fromEvent(video, ConstEventsVideo.Resize)
      .pipe(filter(() => !paintBcastStreamEnabled))
      .subscribe({
        next: () => {
          updateOriginalVideoDimensions()
        },
      })

    return () => {
      subResize.unsubscribe()
    }
  }, [paintBcastStreamEnabled, updateOriginalVideoDimensions, video])

  // - On end painting, check video dimensions as we stop checking it when painting

  useEffect(() => {
    if (paintBcastStreamEnabled === prevPaintBcastStreamEnabled) return

    updateOriginalVideoDimensions()

    //
  }, [paintBcastStreamEnabled, prevPaintBcastStreamEnabled, updateOriginalVideoDimensions])

  // - Move video when zoom is enabled

  useEffect(() => {
    const videoContainer = videoWrapperRef.current
    const video = videoRef.current

    if (!videoContainer || !video || !videoIsPlaying || !hasZoom) return

    const mouseDown$ = fromEvent<MouseEvent>(video, ConstEventsElement.MouseDown)
    const touchStart$ = fromEvent<TouchEvent>(video, ConstEventsElement.TouchStart)

    const mouseDrag$ = fromEvent<MouseEvent>(video, ConstEventsElement.MouseMove).pipe(pairwise())
    const touchDrag$ = fromEvent<TouchEvent>(video, ConstEventsElement.TouchMove).pipe(pairwise())

    const mouseUp$ = fromEvent<MouseEvent>(video, ConstEventsElement.MouseUp)
    const mouseLeave$ = fromEvent<MouseEvent>(video, ConstEventsElement.MouseLeave)
    const touchEnd$ = fromEvent<TouchEvent>(video, ConstEventsElement.TouchEnd)

    const start$ = merge(mouseDown$, touchStart$)
    const drag$ = merge(mouseDrag$, touchDrag$)
    const end$ = merge(mouseUp$, mouseLeave$, touchEnd$)

    const sub = start$
      .pipe(
        filter((evt) => {
          evt.preventDefault()

          const isLeftButton = 'button' in evt && evt.button === ConstMouseButtons.Main

          return isLeftButton || evt.type === ConstEventsElement.TouchStart
        }),
        mergeMap(() => drag$.pipe(takeUntil(end$))),
      )
      .subscribe({
        next: ([prevEvt, curEvt]) => {
          requestAnimationFrame(() => {
            const { prevX, prevY } =
              'touches' in prevEvt
                ? {
                    prevX: prevEvt.touches[0].clientX,
                    prevY: prevEvt.touches[0].clientY,
                  }
                : {
                    prevX: prevEvt.clientX,
                    prevY: prevEvt.clientY,
                  }

            const { curX, curY } =
              'touches' in curEvt
                ? {
                    curX: curEvt.touches[0].clientX,
                    curY: curEvt.touches[0].clientY,
                  }
                : {
                    curX: curEvt.clientX,
                    curY: curEvt.clientY,
                  }

            const [vectorX, vectorY] = [prevX - curX, prevY - curY]

            const newTop = parseInt(video.style.top) - vectorY
            const newLeft = parseInt(video.style.left) - vectorX

            // Negative value
            const maxTop = videoContainer.offsetHeight - video.offsetHeight

            // Negative value
            const maxLeft = videoContainer.offsetWidth - video.offsetWidth

            const finalTop = newTop > 0 ? 0 : newTop - maxTop < 0 ? maxTop : newTop

            const finalLeft = newLeft > 0 ? 0 : newLeft - maxLeft < 0 ? maxLeft : newLeft

            video.style.top = `${finalTop}px`
            video.style.left = `${finalLeft}px`
          })

          //
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [hasZoom, zoom, dispatch, videoRef, videoIsPlaying])

  // - On zoom updated, prevent video to go out of bounds

  useEffect(() => {
    if (!prevZoom || zoom === prevZoom) return

    const video = videoRef.current
    const videoContainer = selfRef.current

    if (!videoContainer || !video) return

    const isZoomOut = zoom < prevZoom

    // Without zoom reset position

    if (zoom === Zoom.Min) {
      video.style.top = '0px'
      video.style.left = '0px'

      return
    }

    const varPercent = zoom / prevZoom

    const curContainerHeight = videoContainer.clientHeight

    const curContainerWidth = videoContainer.clientWidth

    const nextContainerHeight = curContainerHeight * varPercent

    const nextContainerWidth = curContainerWidth * varPercent

    const diffContainerWidth = curContainerWidth - nextContainerWidth

    const diffContainerHeight = curContainerHeight - nextContainerHeight

    const curTop = parseInt(video.style.top)

    const curLeft = parseInt(video.style.left)

    const newTop = curTop + diffContainerHeight / 2

    const newLeft = curLeft + diffContainerWidth / 2

    if (!isZoomOut) {
      video.style.top = `${newTop}px`
      video.style.left = `${newLeft}px`

      return
    }

    // If zoom out we need sticky sides

    const nextHeight = video.clientHeight * varPercent

    const nextWidth = video.clientWidth * varPercent

    const maxTop = videoContainer.offsetHeight - nextHeight

    const maxLeft = videoContainer.offsetWidth - nextWidth

    const finalTop = newTop > 0 ? 0 : newTop - maxTop < 0 ? maxTop : newTop

    const finalLeft = newLeft > 0 ? 0 : newLeft - maxLeft < 0 ? maxLeft : newLeft

    video.style.top = `${finalTop}px`
    video.style.left = `${finalLeft}px`

    //
  }, [prevZoom, zoom])

  // - On original video dimensions change, set size of video wrapper
  //   this happens when video is started

  useEffect(() => {
    if (!originalVideoH || !originalVideoW) return

    setVideoWrapperSize()

    //
  }, [setVideoWrapperSize, originalVideoH, originalVideoW])

  // - Observe self resize to update video wrapper
  //   this happens on resize the window

  useEffect(() => {
    const self = selfRef.current

    if (!self) return

    const resizeObserve = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        setVideoWrapperSize()
      })
    })

    resizeObserve.observe(self)

    return () => {
      resizeObserve.disconnect()
    }
  }, [setVideoWrapperSize])

  // - Play/Pause minivideo on switch painting

  useEffect(() => {
    if (paintBcastStreamEnabled === prevPaintBcastStreamEnabled) {
      return
    }

    const minivideo = miniVideoRef.current

    if (!minivideo) return

    minivideo.pause()

    if (!paintBcastStreamEnabled) {
      setShowMiniVideo(true)
      return
    }

    setTimeout(() => {
      minivideo
        .play()
        .then(() => {
          UtilsLog.devLog('paintBcastStreamEnabled', 'Playing mini video')
        })
        .catch((err) => UtilsLog.devLog('Joined error: cannot autoplay minivideo', err))
    }, 200)

    //
  }, [paintBcastStreamEnabled, prevPaintBcastStreamEnabled])

  // - Remove video source on stream end

  useEffect(() => {
    const video = videoRef.current

    if (!video || screenStreamForVideo) return

    // Video

    video.pause()
    video.srcObject = null

    // Minivideo

    const miniVideo = miniVideoRef.current

    if (!miniVideo) return

    miniVideo.pause()
    miniVideo.srcObject = null

    //
  }, [screenStreamForVideo])

  // ~~~~~~

  return {
    selfRef,
    videoWrapperRef,
    videoRef,
    miniVideoRef,
    toolsWrapperRef,

    videoIsPlaying,
    curCamMicStream,
    hasZoom,
    zoomWidthAndHeight,
    paintBcastStreamEnabled,
    shouldShowMiniVideo,

    remoteCamTrack: dnBcast.remoteCamTrack,
    remoteMicTrack: dnBcast.remoteMicTrack,

    isBcastV1,

    showAi,

    onClickCloseMiniVideo,
  } as const
}
