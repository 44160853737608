import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, filter, mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { ServiceBcastDoamin } from '../../../../../services/bcast-domain/service'
import { UtilsLog } from '../../../../../utils/logs'
import { BroadcastAC } from '../../../../actions/bcast/actions'

type Action = DN.Services.Bcast.Room.Events.Data.DataReceived<{}>

const actionType: Action['type'] = '@dn/Bcast-data-received'

export const bcastV2CommChannelMessagesFromServerEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(actionType),

    filter(({ payload }) => ServiceBcastDoamin.Guards.isPredefMessage(payload.msg)),

    mergeMap(({ payload }) => {
      switch (true) {
        // Confirm close in x mins, no viewers

        case ServiceBcastDoamin.Guards.isConfirmCloseNoViewers(payload.msg):
          return of(BroadcastAC.confirmCloseNoViewers(payload.msg.payload))

        // Close now, no viewers

        case ServiceBcastDoamin.Guards.isCloseNowNoViewers(payload.msg):
          return of(BroadcastAC.closeNowNoViewers())
      }

      UtilsLog.devLog('Unknown prefed msg', payload.msg)

      return EMPTY
    }),
  )
